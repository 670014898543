let selectedProject = 1;

window.projectdemo = function projectdemo(currentId, listCount) {
    selectedProject = currentId;
    for (let i = 1; i <= listCount; i++) {
        document.getElementById('project' + i).style.display = 'none';
    }
    document.getElementById('project' + currentId).style.display = 'block';
}

window.prevProject = function prevProject(listCount) {
    let projectListParent = document.getElementById('projectVideoList');

    for (let i = 1; i <= listCount; i++) {
        document.getElementById('project' + i).style.display = 'none';
    }
    selectedProject--;
    if (selectedProject === 0) {
        selectedProject = listCount;
    }
    document.getElementById('project' + selectedProject).style.display = 'block';

    let itemToReplace = projectListParent.lastElementChild;
    projectListParent.removeChild(projectListParent.lastElementChild);
    projectListParent.insertBefore(itemToReplace, projectListParent.childNodes[0]);
}

window.nextProject = function nextProject(listCount) {
    let projectListParent = document.getElementById('projectVideoList');

    for (let i = 1; i <= listCount; i++) {
        document.getElementById('project' + i).style.display = 'none';
    }
    selectedProject++;
    if (selectedProject > listCount) {
        selectedProject = 1;
    }
    document.getElementById('project' + selectedProject).style.display = 'block';

    let itemToReplace = projectListParent.firstElementChild;
    projectListParent.removeChild(projectListParent.firstElementChild);
    projectListParent.appendChild(itemToReplace);
}